import React, { useState, useEffect } from "react"
import Layout from '../containers/layout'
import FlyingStar from '../containers/FlyingStar'
import styled from 'styled-components'
import PeriodPicker from '../components/Picker/Period'
import DirectionPicker from '../components/Picker/Direction'
import { createFlyingstarChart, getAnnualStar, getMonthlyStar, createBasePlate } from '../cm-lib/XuanKongFlyingStar'
import { Box, Button, DateInput, Layer, TextArea, TextInput } from 'grommet'
import { TWENTY_FOUR_MOUNTAIN } from '../cm-lib/constants'
import { create } from "react-test-renderer"
import { setStorage, getStorage } from '../helpers/index'
import { navigate } from 'gatsby'
import { CHARTLIST_KEY } from '../constants' 
import { mediaQueries } from '../helpers/index'

const Container = styled.div`
  background-color: #db045a;
  width: 100%;
  padding-top: 100%;
  position: relative;
`

const Content = styled.div`
  background-color: white;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
`

const Direction = styled.div`
  color: white;
  position: absolute;
  font-size: 1.5em;
  ${props => props.css}
`

const Grid = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
`

const Cell = styled.div`
  aspect-ratio: 1;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  position: relative;
  display: flex;
`

const CellContent = styled.div`
  background-color: red;
  width: 100%;
  heigth: 100%;
`

const Star = styled.div`
  font-size: 24px;
  position: absolute;
`

const MountainStar = styled.div`
  font-size: 1.5em;
  position: absolute;
  left: 20%;
  top: 10px;
  ${mediaQueries("sm") `
  font-size: 2em;
`}
`
const WaterStar = styled.div`
  font-size: 1.5em;
  position: absolute;
  right: 20%;
  top: 10px;
  ${mediaQueries("sm") `
  font-size: 2em;
`}
`

const BaseStar = styled.div`
  font-size: 1.5em;
  margin: auto;
  ${mediaQueries("sm") `
    font-size: 2em;
  `}
`

const MonthlyStar = styled.div`
  font-size: 24px;
  position: absolute;
  bottom: 10px;
  
  color: red;
  right: 20%;
`

const AnnualStar = styled.div`
  font-size: 24px;
  position: absolute;
  bottom: 10px;
  left: 20%;
  color: blue;
`

const FacingSymbol = styled.div`
  font-size: 1.5em;
  position: absolute;
  top: 40%;
  right: 10px;
  color: red;
  
`

const SittingSymbol = styled.div`
  font-size: 1.5em;
  position: absolute;
  top: 40%;
  right: 10px;
`

const DirectionListTemplate = [
  {

    label: 'SE',
    css: `top: 2%; left: 2%;
    ${mediaQueries("sm") `
    top: 4%; left: 4%;
  `}
    `
  },
  {

    label: 'S',
    css: `top: 2%; left: 50%;
    ${mediaQueries("sm") `
    top: 2%;
  `}
    `
  },
  {

    label: 'SW',
    css: `top: 2%;right: 2%;
    ${mediaQueries("sm") `
      top: 4%;right: 4%;
    `}
    `
  },
  {

    label: 'E',
    css: `top: 50%;left: 2%;
    ${mediaQueries("sm") `
    left: 4%;
  `}
    `
  },
  {

    label: '',
    css: `top: 50%; left: 50%;
    
    `
  },
  {

    label: 'W',
    css: `top: 50%; right: 2%;
      ${mediaQueries("sm") `
        right: 4%;
      `}
    
    `
  },
  {

    label: 'NE',
    css: `bottom: 2%; left: 2%;
    ${mediaQueries("sm") `
      bottom: 4%; left: 4%;
    `}
    `
  },
  {

    label: 'N',
    css: `bottom: 2%; left: 50%;
    ${mediaQueries("sm") `
      bottom: 4%;
    `}
    `
  },
  {
    label: 'NW',
    css: `bottom: 2%; right: 2%;
    ${mediaQueries("sm") `
      bottom: 4%; right: 4%;
    `}
     
    `
  },
]

const Home = () => {

  

  const findMountain = (facing) => {
    return TWENTY_FOUR_MOUNTAIN.find(e => e.facing === facing)
  }

  const initialFacing = 's2';

  const [datamodel, setDataModel] = useState({})
  const [savingChart, setSavingChart] = useState(false)
  const [showVisitStar, setShowVisitStar] = useState(false)
  const [period, setPeriod] = useState(8);
  const [facing, setFacing] = useState(initialFacing);
  const [mountain, setMountain] = useState(findMountain(initialFacing))
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [chart, setChart] = useState([...Array(9).keys()].map(e => ({})))
  const [orientationList, setOrientationList] = useState(['SE', 'S', 'SW', 'E', '', 'W', 'NE', 'N', 'NW'])
  const [annualStar, setAnnualStar] = useState(getAnnualStar(selectedDate));
  const [monthlyStar, setMonthlyStar] = useState(getMonthlyStar(selectedDate));

  useEffect(() => {
    setAnnualStar(getAnnualStar(selectedDate))
    setMonthlyStar(getMonthlyStar(selectedDate))
  }, [selectedDate])

  useEffect(() => {


  }, [showVisitStar])

   
  const orientateRight = (list) => {
    const array = [...Array(9).keys()]
    list.forEach((e, i) => {
      if([0, 1].includes(i)) {
        array[i+1] = e
      }
      if([2].includes(i)) {
        array[5] = e
      }
      if([3].includes(i)) {
        array[0] = e
      }
      if([4].includes(i)) {
        array[4] = e
      }
      if([5].includes(i)) {
        array[8] = e
      }
      if([6].includes(i)) {
        array[3] = e
      }
      if([7, 8].includes(i)) {
        array[i - 1] = e
      }
    })
    return array;
  }


  const DirectionList = orientationList.map((label, i) => ({
    label,
    css: DirectionListTemplate[i].css
  }))



  useEffect(() => {

    const baseAnnualStar = createBasePlate(annualStar)
    const baseMonthlyStar = createBasePlate(monthlyStar);
    const flyingStarChart = createFlyingstarChart(facing, period);
    const newChart = flyingStarChart.map((e, i) => {
      return {
        ...e,
        annual: baseAnnualStar[i],
        monthly: baseMonthlyStar[i]
      };
    })
    setChart(newChart)
    setMountain(findMountain(facing))
  }, [period, facing, annualStar, monthlyStar, selectedDate])

  return <Layout>
    <Box direction="row">
    <DirectionPicker onChange={setFacing} />
    <PeriodPicker onChange={value => setPeriod(value)} />
    
    </Box>
    <Box direction="row">
      <Button label="Orientate" onClick={() => {
        let stop = false;
        let newOrientation = orientateRight(orientationList)
        while(!stop) {
          if([mountain.facing.substring(0, mountain.facing.length - 1), 's'].includes(newOrientation[1].toLowerCase())) {
            stop = true;
            setOrientationList(newOrientation)
          }
          newOrientation = orientateRight(newOrientation)
        }
        
      }} />
      <Button label={`${showVisitStar ? 'Hide' : 'Show'} Visting Star`} onClick={() => setShowVisitStar(!showVisitStar)} />

      <Button label={`Save Chart`} onClick={() => {
        setDataModel({
          ...datamodel,
          facing,
          period
        })
        setSavingChart(true)} 
      }/>
      {savingChart && (
        <Layer
          onEsc={() => setSavingChart(false)}
          onClickOutside={() => setSavingChart(false)}
          pad="10px"
        >
          <Box pad="20px">
          <TextInput placeholder="Name" onChange={event => setDataModel({
            ...datamodel,
            name: event.target.value,
          })} />
          <TextInput value={datamodel.period} />
          <TextInput value={datamodel.facing} />
          <TextArea placeholder="Description" onChange={event => setDataModel({
            ...datamodel,
            description: event.target.value,
          })}></TextArea>
          <Button label="Save" onClick={
            () => {
     
              const list = getStorage(CHARTLIST_KEY)
                if(list) {
                  list.push(datamodel)
                  setStorage(CHARTLIST_KEY, list)
                } else {
                  setStorage(CHARTLIST_KEY, [datamodel])
                }
                setSavingChart(false)
              }
              
          } />
          
          <Button label="Cancel" onClick={() => setSavingChart(false)} />
          </Box>
        </Layer>
      )}
    </Box>

    <Box>
    <DateInput
      format="mm/dd/yyyy"
      value={(new Date()).toISOString()}
      onChange={({ value }) => { 
        var timestamp = Date.parse(value);
        if (isNaN(timestamp) == false) {
          setSelectedDate(new Date(value))
        }
        
      }}
    />
    </Box>
    <Container>
      {
        DirectionList.map((e, i) => <Direction key={i} css={e.css}> { e.label } </Direction>)
      }


      <Content>
        <Grid>
          {
            chart && chart.length && DirectionList.map((d, ii) => {
              const e = chart.find((f, i) => f.direction === d.label)
              return <Cell key={ii}> 

              
              {
                mountain && <>
                <FacingSymbol> {  mountain.facing.substring(0, mountain.facing.length - 1).toUpperCase() === e?.direction ? mountain.name : "" } </FacingSymbol>
                <SittingSymbol> {  mountain.sitting.substring(0, mountain.facing.length - 1).toUpperCase() === e?.direction ? mountain.sittingName : "" } </SittingSymbol>
                <BaseStar>{ e?.baseNumber }</BaseStar>
                {
                  showVisitStar && <>
                  <MonthlyStar>{ e?.monthly }</MonthlyStar>
                  <AnnualStar>{ e?.annual }</AnnualStar>
                  </>
                }
                <MountainStar>{ e?.mountainNumber }</MountainStar>
                <WaterStar>{ e?.waterNumber }</WaterStar>
                </>
              }
            </Cell>
            })
          }
        </Grid>
      </Content>
    </Container>
  </Layout>
}

export default Home
